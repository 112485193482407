@import "../variables.module.scss";

.chartsHolder {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  width: 100%;
  max-width: 100%;
  //overflow-x: scroll;
  overflow-y: hidden;
  position: sticky;

  .chart {
    width: 108px !important;
    height: 350px !important;

    border: 1px $light_grey solid;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    padding: 5px 20px 5px 20px;
  }

  .indvChart {
    width: 108px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    position: relative;
  }

  .label {
    padding: 2px;
    width: 108px;
    text-align: center;
    border: 1px $light_grey solid;
    min-height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.legend {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  .item {
    display: flex;
    flex-direction: row;
    margin: 10px;
    .leftBox {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      background-color: rgba(227, 30, 37, 0.329);
      border: 1px solid $light_grey;
    }

    .usedBox {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      background-color: $gniezno_red;
      border: 1px solid $light_grey;
    }
  }
}

.overflow {
  color: rgb(227, 30, 36);
  font-weight: bold;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  position: relative;
}

.floatingTitle {
  top: -40px;
  left: -10px;
  font-size: 17px;
  font-weight: 600;
  color: $grey;
  position: absolute;
}

.floatingUnit {
  color: white !important;
  background-color: $grey;
  position: absolute;
  bottom: 35px;
  left: -5px;
  padding: 1px 3px;
}
