@import url("https://fonts.googleapis.com/css2?family=Prompt&display=swap");
@import "../variables.module.scss";

.qrWrapper {
  font-family: "Century Gothic", "Prompt", sans-serif !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 10px;
  border: 1px solid rgba(17, 17, 17, 0.295);
  border-radius: 15px;
  max-width: 400px;

  .title {
    p {
      margin: 0 !important;
    }
    padding: 10px;
    width: 100%;
    background-color: $gniezno_red;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(17, 17, 17, 0.295);
  }

  .qr {
    margin: 30px 30px 15px;
  }

  .qrStringified {
    color: $grey;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .userList {
    display: block;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    .user {
      margin: 10px 0 0 0;
      max-width: 600px;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 8px;
      border-bottom: 1px dashed;

      .userData {
        .userRow {
          display: flex;

          p {
            margin-right: 2px;
          }
        }
      }

      .userQR {
      }
    }
  }
}

.invisibleContent {
  display: none;
  position: absolute !important;
}

.toPrintBody {
  position: relative !important;

  .qrWrapper {
    position: absolute;
    right: 2cm;
    top: 5cm;
    width: 7cm !important;
    height: 8cm !important;
    margin: 0 !important;
  }

  .qr {
    display: table !important;
    margin: 10px !important;
    width: min-content !important;
    height: min-content !important;
  }
  .invisibleContent {
    display: inline !important;
    position: relative !important;

    .mapIMG {
      position: absolute !important;
      bottom: -17.1cm;
      max-width: 20cm;
      left: -15cm;
    }
    .usefulIMG {
      position: absolute !important;
      transform: rotate(-90deg);
      max-width: 15cm;

      left: -17.5cm;
      bottom: -0.75cm;
    }

    .logo {
      position: absolute !important;
      width: 5cm;
      height: 4cm;
      display: flex;
      justify-content: space-between;
      align-items: center;
      left: -3.2cm;
      top: -11.25cm;

      img {
        width: 3cm;
        height: 3cm;
      }

      .txt {
        color: $gniezno_red;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: -20px;
        width: min-content;
        text-align: right;
      }
    }
  }

  canvas {
    height: 180px !important;
    width: 180px !important;
    margin: 10px;
  }
}

@media print {
  .user {
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  /* mobile */
  .qr {
    margin: 2px !important;
  }
}
