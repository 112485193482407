$yellow: #f4cf00;
$grey: #1f1f1f;

$light_grey: #2b2a29;
$gniezno_red: rgb(227, 30, 36);

:export {
  lightgrey: $light_grey;
  gnieznored: $gniezno_red;
}
