body {
  margin: 0;
  font-family: "Prompt", sans-serif !important;
}

.toPrintBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Prompt&display=swap");
