@import url("https://fonts.googleapis.com/css2?family=Prompt&display=swap");
@import "../../variables.module.scss";

.mainContainer {
  //background-color: #e3f2fd;
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  font-family: "Century Gothic", "Prompt", sans-serif !important;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  .errorAlert {
    background-color: $grey !important;
    color: white !important;
  }

  .wasteTitle {
    margin-top: 5%;
    text-align: center;
  }

  .panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 30px;
    border: 1px solid rgba(17, 17, 17, 0.295);
    border-radius: 15px;
    margin: 20px;
    width: 90%;
    max-width: 400px;
    position: relative;
    .floater {
      position: absolute;
      top: -15px;
      left: 15px;
      background-color: $grey;
      color: white;
      padding: 3px 10px;
      font-weight: 600;
    }

    .floaterBot {
      position: absolute;
      bottom: -15px;
      right: 20px;
      background-color: $grey;
      color: white;
      padding: 3px 10px;
    }
  }

  .logo {
    height: 200px;
    width: auto;
  }

  .landingText {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .wLogo {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      color: $gniezno_red;
      font-weight: 600;
      font-size: 40px;
      margin-bottom: -20px;
      width: min-content;
      text-align: right;
    }
    .desc {
      color: $grey;
      font-size: 20px;
    }
  }

  .inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 95%;
    max-width: 400px;

    .txtField {
      margin: 5px 20px;
      width: 250px;
    }

    .checkboxes {
      display: flex;
      flex-direction: column;
      max-width: 270px;
      margin-bottom: 10px;
    }

    margin: 10px;
    border: 1px solid rgba(17, 17, 17, 0.295);
    border-radius: 15px;
  }
  .declarationTypeForm {
    margin: 20px 0px 10px 0px;
    .boldTxt {
      font-weight: 600 !important;
    }
  }

  .doneButtonContainer {
    width: 100%;
    border-top: 1px solid rgba(17, 17, 17, 0.295);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(34, 34, 34, 0.068);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-top: 20px;
  }
  .errors {
    text-align: center;
    color: red;
    font-weight: bold;
  }

  .qrContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .qrHolder {
      max-width: 500px;
    }
    .button {
      margin: 5px;
    }
  }

  .printContent {
    width: 400px;
  }
}

.btmButton {
  display: flex;
  padding: 10px 15px;
  border: 1px solid rgba(17, 17, 17, 0.295);
  border-radius: 15px;
  color: $grey;
  align-items: center;
  margin: 5px;
  &:hover {
    color: $gniezno_red;
    border-color: $gniezno_red;
    transition: 0.3s;
  }

  .icon {
    margin-right: 5px;
  }
}

.option {
  min-height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .leftDiv {
    display: block;
    .title {
      align-self: flex-start;
      font-weight: bold;
      font-size: larger;
      margin-bottom: 0px;
    }
    .type {
      color: grey;
      font-size: small;
    }
  }

  .rightDiv {
    //align-self: flex-end;
    display: flex;
    flex-direction: column;
    .thumbnail {
      align-self: flex-end;
      height: auto;
      width: 25px;
    }
    .dest {
      align-self: flex-end;
      text-align: right;
    }
  }
}

.selectedItem {
  margin-top: 50px;
  //width: 80%;
  width: 80%;
  max-width: 700px;
  display: flex;
  flex-direction: column;

  .Box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    flex-direction: row;
    margin-bottom: 30px;

    .prefix {
      //align-self: flex-start;
      align-self: center;
      font-size: 18px;

      align-self: center;
      text-align: right;
      margin: 0px;
    }

    .name {
      font-size: 26px;
      align-self: center;
      font-weight: 600;
      margin: 0px;
    }
  }
}

.autocomplete {
  width: 80%;
  max-width: 700px;
}

@media only screen and (max-width: 500px) {
  /* mobile */
  .title {
    font-size: 20px !important;
  }

  .wasteTitle {
    font-size: 30px;
  }

  .selectedItem {
    .Box {
      .prefix {
        font-size: 14px;
      }
      .name {
        font-size: 18px;
      }
    }
  }

  .logo {
    height: 100px !important;
  }
}

@media only screen and (max-width: 800px) {
  /* mobile */
  .autocomplete {
    width: 98%;
  }

  .selectedItem {
    width: 98%;
  }

  .wasteTitle {
    font-size: 30px;
  }

  .logo {
    height: 100px !important;
  }
}

@media only screen and (min-height: 1100px) {
  /* mobile */
  .mainContainer {
    height: 90vh;
  }

  .notFoundContainer {
    height: 90vh;
  }
}
