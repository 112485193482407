@import url("https://fonts.googleapis.com/css2?family=Prompt&display=swap");
@import "../variables.module.scss";
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $grey;
  font-family: "Century Gothic", "Prompt", sans-serif !important;
  position: relative;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  .headerTitle {
    font-size: 24px;
    margin: 10px;
    font-weight: 800;
    color: $gniezno_red;
    display: flex;
    justify-content: center;
    p {
      margin: 0 !important;
    }

    .panelTxt {
      color: white !important;
      margin-left: 10px !important;
    }
  }

  .panelButton {
    position: absolute;
    left: 10px;
  }
}
